<template>
  <div class="component-wrapper">
    <!-- <currency-rate /> -->
    <expiring-order />
    <b-row>
      <b-col>
        <b-skeleton-img></b-skeleton-img>
      </b-col>
      <b-col>
        <b-skeleton-img></b-skeleton-img>
      </b-col>
      <b-col cols="12" class="mt-3">
        <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "@api";
import CurrencyRate from "./components/CurrencyRate.vue";
import ExpiringOrder from "./components/ExpiringOrder.vue";

export default {
  name: `MainDashboard`,
  components: { ExpiringOrder, CurrencyRate },
  computed: {},
  methods: {},
};
</script>
